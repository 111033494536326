import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import MiniHeader from './MiniHeader';
import * as styles from './Kontakt.module.css';

function Kontakt() {
    const data = useStaticQuery(graphql`
        query {
            configJson {
                sectionhead {
                    kontakt
                    leistungen
                    ueberuns
                }
            }
        }
    `)

    return (
        <div>
            <MiniHeader headline={data.configJson.sectionhead.kontakt}/>
            <div className={styles.kontaktContainer}>
                <p className={styles.kontaktText}>Sie möchten einen Termin vereinbaren? Oder absagen? Oder nur einfach mal was loswerden? Dann schreiben Sie uns. Gerne rufen wir Sie zurück.</p>
                <p>Ihre Daten behandeln wir selbstverständlich streng vertraulich.</p>
                <br/>
                
                <form 
                    className={styles.form} 
                    method="post"
                    action="rabbithole.php"
                    id="cont-form">

                    <label>Vor- und Nachname*</label>
                    <input placeholder="Bitte eintragen"
                        name="name" required 
                    />
                    <label>Telefon<span>*</span></label>
                    <input placeholder="Bitte eintragen"
                        name="telefon" required
                    />
                    <label>Email*</label>
                    <input placeholder="Bitte eintragen"
                        name="email" required
                    />
                    <label>Ihre Nachricht*</label>
                    <textarea placeholder="Bitte eintragen" name="nachricht"/>
                    <p className={styles.note} >*Alle Felder sind Pflichtfelder.</p>

                    <button className="defaultBtn"
                    type="submit" formMethod="post" form="cont-form" formAction="rabbithole.php"
                    >Absenden</button>
                </form>
            </div>
        </div>

    )
}

export default Kontakt
