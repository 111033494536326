import React from "react";
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import Kontakt from "../components/Kontakt";
import Info from "../components/Info";
import './index.css';


const KontaktPage = () => {
  return (
    <>    
      <Helmet>
          <title>Kontakt</title>
          <meta name='description' content="Nehmen Sie Kontakt zu uns auf. Gerne rufen wir zurük."/>
          <meta name='keywords' content='Physiotherapie, Krankengymnastik, Manuelle Lymphdrainage, Fußreflexzonenmassage, Wärmetherapie, Kältetherapie, Medizinische Massagetherapie, Neurologische Behandlung, Bobath Erwachsene, Faszienbehandlung, Elektrotherapie, Ultraschall, Flossing, Gerätetraining, Hausbesuche, Düren'/>
          <meta property='og:title' content="Kontakt"/>
          <meta property='og:type' content='Website'/>
          <meta property='og:description' content="Nehmen Sie Kontakt zu uns auf. Gerne rufen wir zurük."/>
          <meta property='og:image' content=''/>
          <meta property='og:locale' content='de'/>
            <meta property='og:url' content={`https://www.physiotherapie-winthagen.de/kontakt`}/>
          <link rel="canonical" href={`https://www.physiotherapie-winthagen.de/kontakt`}/>
      </Helmet>  
      <Layout>
          <Kontakt/>
          <Info />
      </Layout>
    </>
  )
}

export default KontaktPage